import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import {
  selectedCompanySelector,
  setSelectedCompany,


} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddCompanyMutation,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import DZRadioGroup from "@jumbo/dz-components/radio-button/DzRadioGroup";
import MultiImageUpload from "app/DZcomponents/MultiImageUpload/MultiImageUpload";

const AddUpdateCompany = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector(selectedCompanySelector);

  const { data: res_get } = useGetCompanyQuery(selectedCompany?.id);
  const selectedLanguage = useSelector(selectedLanguageSelector);


  const [addCompany, responseAdd] = useAddCompanyMutation();
  const bluePrint = BLUE_PRINTS.company;
  const [companyState, setCompanyState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();
  const [selectedFile, setSelectedFile] = useState(
  );
  const addUpdateCompanyData = async () => {
    const formData = new FormData();

   
    formData.append("name", companyState.name);
    formData.append("email", companyState.email);
    formData.append("person_name", companyState.person_name);
    formData.append("cvr", companyState.cvr);
    formData.append("file", selectedFile);

    const res = await addCompany(formData);
       

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message);
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };
  const { error, resetCount } = useResponseError(responseAdd);

  useEffect(() => {
    setCompanyState(selectedCompany ? res_get?.data?.company : bluePrint);
  }, [res_get, selectedCompany, bluePrint]);
  
  const filehandle = (e) => {
    console.log("file handle image", e.target.files[0]);
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
     
     
    }
  };
  
  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
         
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true && responseAdd 
        }
      />

      {companyState ? (
        <div style={addUpdateDivStyleGlobal}>
          <DZTextValidation
            error={error?.name}
            label="Company"
            variant="outlined"
            value={companyState?.name}
            onChange={(e) => {
              setCompanyState((x) => ({ ...x, name: e.target.value ,
              }));
            }}
            placeholder="Company Name"
          />
          <DZTextValidation
            error={error?.person_name}
            label="Owner Name"
            variant="outlined"
            value={companyState?.person_name}
            onChange={(e) => {
              setCompanyState((x) => ({ ...x, person_name: e.target.value ,
              }));
            }}
            placeholder="Owner Name"
          />
          <DZTextValidation
            error={error?.email}
            label="Email"
            variant="outlined"
            value={companyState?.email}
            onChange={(e) => {
              setCompanyState((x) => ({ ...x, email: e.target.value ,
              }));
            }}
            placeholder="Email"
          />
          <DZTextValidation
            error={error?.cvr}
            label="CVR"
            variant="outlined"
            value={companyState?.cvr}
            onChange={(e) => {
              setCompanyState((x) => ({ ...x, cvr: e.target.value ,
              }));
            }}
            placeholder="CVR"
          />
       
 
            <label htmlFor="upload-photo">
              <input
               
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={filehandle}
              />
           
            </label>
         
      


      


          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedCompany(null));
              }}
            >
              Cancel
            </Button>
           
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateCompanyData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedCompany(null));
                }}
              >
                Send
              </Button>
           
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateCompany;
