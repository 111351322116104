import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'SelectedStuffReducer',
    initialState: {
       
        selectedAdmin: null,
        selectedCompany: null,
       
        selectedUserLogin: null,
       
        selectedUser: null,
        selectedService: null, 
        selectedContact: null, //
      
    },

    reducers: {
        setSelectedUserLogin: (state, action) => {
            state.selectedUserLogin = action.payload;
        },
    
        setSelectedAdmin: (state, action) => {
            state.selectedAdmin = action.payload;
        },
      
    
        setSelectedCompany: (state, action) => {
            state.selectedCompany = action.payload;
        },
      

        setSelectedUser: (state, action) => {
           
            state.selectedUser = action.payload;
        },
        setSelectedService: (state, action) => { 
     
            state.selectedService = action.payload;
        },
        setSelectedContact: (state, action) => { //
     
            state.selectedContact = action.payload;
        },

     
     
        resetSelectedStuffReducer: (state) => {
            state.selectedUserLogin = null;
          
            state.selectedAdmin= null;
            state.selectedCompany= null;
           
            state.selectedUser = null;
            state.selectedService = null; 
            state.selectedContact = null; //
     


        },
    },
});

export const {
    setSelectedUserLogin,
   
    setSelectedAdmin,
    setSelectedCompany,

    setSelectedUser,
    setSelectedService, 
    setSelectedContact, //

   

} = slice.actions;
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const selectedUserLoginSelector = (state) => state.SelectedStuffReducer.selectedUserLogin;
export const selectedAdminSelector = (state) => state.SelectedStuffReducer.selectedAdmin;
export const selectedCompanySelector = (state) => state.SelectedStuffReducer.selectedCompany;
export const selectedUserSelector = (state) => state.SelectedStuffReducer.selectedUser;
export const selectedServiceSelector = (state) => state.SelectedStuffReducer.selectedService; 

export const selectedContactSelector = (state) => state.SelectedStuffReducer.selectedContact; //

//old



export default slice.reducer;