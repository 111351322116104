import { selectedCompanySelector } from 'app/rkt_query/SelectedStuffReducer';
import {  useGetCompanyQuery } from 'app/rkt_query/storeApis';
import React from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewCompany = () => {

    const selectedCompany = useSelector(selectedCompanySelector);
    const { data: res_get } = useGetCompanyQuery(selectedCompany?.id);
    const companyArray =
        [
            { label: 'Company', data: res_get?.data?.company?.name },
            { label: 'Owner', data: res_get?.data?.company?.person_name },
            { label: 'Email', data: res_get?.data?.company?.email },
            { label: 'Email', data: res_get?.data?.company?.cvr },
        ]

    return (
        <div>

            {
                  selectedCompany?.id ?
                        <DZGenericView obj={companyArray} closeBtn={true}  /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewCompany

