import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllCompaniesQuery, BLUE_PRINTS, useGetCompanyQuery, useDeleteCompanyMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Add from '.';
import { setSelectedCompany } from 'app/rkt_query/SelectedStuffReducer';
import ViewCompany from './ViewCompany';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import AddUpdateCompany from './AddUpdateCompany';

const CompanyList = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteCompany, responseDelete] = useDeleteCompanyMutation();
  const { data: res_get_all, isLoading: isLoadingCompanies, error: errorCompanies } = useGetAllCompaniesQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.company
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedCompany(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedCompany(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedCompany(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedCompany(row)); }
  bluePrint.handleDelete = (id) => { deleteCompany(id); }

  


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={"Companies"}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable showAddButton={true} btnTitleAdd="Companies" hideEditBtn={false}  showLanguageSwitcher={false}  data={res_get_all?.data?.companies} bluePrint={bluePrint} ViewModel={() => <ViewCompany />} ModalContent={() => <AddUpdateCompany onClose={hideDialog} />} />
         
        </div>
      </JumboDemoCard>
    </>
  )
}


export default CompanyList